// Risk thresholds for overall likelihood score
export const OVERALL_RISKS = {
  HIGH_DECIMAL: 0.8,
  LOW_DECIMAL: 0.6
}

// Risk thresholds for individual trademarks combinedSimilarity score
export const MARK_RISKS = {
  HIGH_DECIMAL: 0.8,
  LOW_DECIMAL: 0.6
}

export const CONFUSION_RISKS = {
  HIGH_DECIMAL: 0.85,
  LOW_DECIMAL: 0.75
}

// Risk thresholds for individual goods and services similarity score
export const GS_RISKS = {
  HIGH_DECIMAL: 0.8
}

// Nice class for goods is 1-34 and services 35-45
export const NICE_CLASSES = {
  MAX_GOODS: 34,
  MIN_SERVICES: 35
}

export const MAX_CONFLICTS = 20

export const PROVINCES = {
  CA: {
    AB: 5,
    BC: 12,
    MB: 13,
    NB: 15,
    NL: 15,
    NT: 5,
    NS: 15,
    NU: 5,
    ON: 13,
    PE: 15,
    QC: 14.975,
    SK: 11,
    YT: 5
  },
  US: {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    'American Samoa': 'AS',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    'District Of Columbia': 'DC',
    Delaware: 'DE',
    Florida: 'FL',
    Georgia: 'GA',
    Guam: 'GU',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    'Northern Mariana Islands': 'MP',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    'Virgin Islands': 'VI',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
  }
}

export const SUPPORTED_FILE_TYPES = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  'svg+xml': 'image/svg+xml',
  pdf: 'application/pdf'
}

export const MAX_FILE_SIZE = 20000000

export const PDF_ICON_URL =
  'https://www.iconpacks.net/icons/2/free-pdf-icon-2616-thumb.png'

export const INDUSTRIES = [
  'Food',
  'Dining',
  'Drinks',
  'Automotive ',
  'Business Support / Consulting / Management',
  'Business Supplies',
  'Computers',
  'IT & Technology',
  'Electronics',
  'Digital / Photography / Design',
  'Construction',
  'Contractors',
  'Education',
  'Entertainment',
  'Health',
  'Medicine',
  'Home & Home Supplies',
  'Garden / Outdoor',
  'Legal',
  'Financial',
  'Medical Services',
  'Wholesale',
  'Distribution',
  'Merchants (Retail)',
  'Miscellaneous',
  'Personal Care Products & Services',
  'Animal Care & Services',
  'Real Estate',
  'Travel',
  'Transportation',
  'Geo Locations',
  'Online Services',
  'Social Media',
  'Online Commerce',
  'Casino',
  'Outdoor / Indoor Sports',
  'Political',
  'Religion or Charity',
  'Fashion / Art',
  'Clothing / Accessories ',
  'Army / Military',
  'News / Publishers / Writers'
]

export const status = {
  Protected: 'Registered',
  Registered: 'Registered',
  'Accepted for Publication': 'Pending',
  Advertised: 'Pending',
  Allowed: 'Pending',
  Approved: 'Pending',
  'Default - Allowed': 'Pending',
  'Default - Registration Pending': 'Pending',
  'Default - Searched': 'Pending',
  'Entered on the List': 'Pending',
  Formalized: 'Pending',
  Granted: 'Pending',
  Merged: 'Pending',
  Opposed: 'Pending',
  Pending: 'Pending',
  'Pre-formalized': 'Pending',
  'Refused - Appeal in Progress': 'Pending',
  'Refused - Awaiting Appeal': 'Pending',
  'Registration Pending': 'Pending',
  Searched: 'Pending',
  Abandoned: 'Inactive',
  'Abandoned - Section 38(11)': 'Inactive',
  'Abandoned - Section 38(7)': 'Inactive',
  'Abandoned Section 36': 'Inactive',
  'Abandoned Section 40(3)': 'Inactive',
  Cancelled: 'Inactive',
  'Cancelled by Owner': 'Inactive',
  'Deemed Never Filed': 'Inactive',
  Expunged: 'Inactive',
  'Expunged Section 45(3)': 'Inactive',
  Inactivated: 'Inactive',
  'Inactive - Partial Transfer': 'Inactive',
  'Not recommended': 'Inactive',
  Refused: 'Inactive',
  'Refused - Section 38(8)': 'Inactive',
  Removed: 'Inactive',
  Revoked: 'Inactive',
  Surrendered: 'Inactive',
  Withdrawn: 'Inactive',
  'Withdrawn by Owner': 'Inactive'
}

export const SCHEMATYPES = {
  ARTICLE: 'ARTICLE',
  FAQ: 'FAQ'
}
