import { MARK_RISKS, NICE_CLASSES } from './constants'
import riskLow from './assets/icons/risk_low.svg'
import riskHigh from './assets/icons/risk_high.svg'
import riskMed from './assets/icons/risk_med.svg'
import { detect } from 'detect-browser'

/**
 * Counts the number of trademark conflicts that are high risk.
 * @param trademarks Trademarks in conflict.
 * @returns {number} Number of high risk trademarks.
 */
export function countHighRiskConflicts(trademarks) {
  var count = 0

  trademarks?.map((trademark) => {
    if (trademark.combineSimilarity >= MARK_RISKS.HIGH_DECIMAL) {
      count++
    }
  })

  return count
}

/**
 * Determine risk view data for a trademark's combined similarity score.
 *
 * @param markScore The trademark's combineSimilarity score from the search results.
 * @returns {{image, text: string}} Image and text to display given the trademark's score.
 */
export function processMarkRisk(markScore) {
  var img, text

  if (markScore <= MARK_RISKS.LOW_DECIMAL) {
    img = riskLow
    text = 'Low'
  } else if (markScore >= MARK_RISKS.HIGH_DECIMAL) {
    img = riskHigh
    text = 'High'
  } else {
    img = riskMed
    text = 'Medium'
  }

  return {
    image: img,
    text
  }
}

/**
 * From list of goods and services, returns goods only if index=0 and services if index=1.
 *
 * @param items Array of goods and services from search results.
 * @param index 0=goods, 1=services
 * @returns {*}
 */
export function processProductsType(items, index) {
  var _array = items
    .map(function (item) {
      if (
        index === 0 &&
        Number.parseInt(item.niceClass) <= NICE_CLASSES.MAX_GOODS
      ) {
        return item.text
      } else if (
        index === 1 &&
        Number.parseInt(item.niceClass) >= NICE_CLASSES.MIN_SERVICES
      ) {
        return item.text
      }
    })
    .filter((item) => {
      return item
    })

  return _array
}

/**
 * Function that checks if script element is present in head.
 * @param {*} url The url to look for
 * @returns {boolean} Return if the script is present of not
 */
export function isMyScriptLoaded(url) {
  if (!url) url = ''
  var scripts = document.getElementsByTagName('script')
  for (var i = scripts.length; i--; ) {
    if (scripts[i].src == url) return true
  }
  return false
}

/**
 * Function to return if browser is safari.
 * @return {boolean}  Returns true or false
 */
export function isSafariBrowser() {
  const browser = detect()
  // handle the case where we don't detect the browser
  if (browser) {
    return browser.name == 'safari' || browser.name == 'ios' ? true : false
  }
}

/**
 * Function to format given string to CA/US
 * specific number format.
 * @param {String}  number unformatted phone number
 * @return {String} Formatted CA, US number
 */
export function formatPhoneNumber(number) {
  try {
    // remove any chars and regex takes the strings last 10 chars(digits and chars)
    const match = number.replace(/\D+/g, '').match(/([^\d]*\d[^\d]*){1,10}$/)[0]
    // splitting each part of the number and using the substring value if the length is enough
    const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
    const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
    const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
    return `${part1}${part2}${part3}`
  } catch (error) {
    return ''
  }
}

/**
 * Function to check phone # format is in CA/US.
 * @param {String}  value formatted phone number
 * @return {Boolean} true or false
 */
export function isValidPhone(value) {
  const phoneRegex = RegExp(/(\D)(\d{1,3})(\D) (\d{0,3})-(\d{0,4})$/)
  if (phoneRegex.test(value) === false || value.length < 14) {
    return false
  } else {
    return true
  }
}
