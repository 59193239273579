// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-program-js": () => import("./../../../src/pages/affiliate-program.js" /* webpackChunkName: "component---src-pages-affiliate-program-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-book-free-consult-js": () => import("./../../../src/pages/book-free-consult.js" /* webpackChunkName: "component---src-pages-book-free-consult-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-company-privacy-policy-js": () => import("./../../../src/pages/company/privacy-policy.js" /* webpackChunkName: "component---src-pages-company-privacy-policy-js" */),
  "component---src-pages-company-terms-conditions-js": () => import("./../../../src/pages/company/terms-conditions.js" /* webpackChunkName: "component---src-pages-company-terms-conditions-js" */),
  "component---src-pages-en-ca-affiliate-program-js": () => import("./../../../src/pages/en-ca/affiliate-program.js" /* webpackChunkName: "component---src-pages-en-ca-affiliate-program-js" */),
  "component---src-pages-en-ca-articles-js": () => import("./../../../src/pages/en-ca/articles.js" /* webpackChunkName: "component---src-pages-en-ca-articles-js" */),
  "component---src-pages-en-ca-book-free-consult-js": () => import("./../../../src/pages/en-ca/book-free-consult.js" /* webpackChunkName: "component---src-pages-en-ca-book-free-consult-js" */),
  "component---src-pages-en-ca-checkout-js": () => import("./../../../src/pages/en-ca/checkout.js" /* webpackChunkName: "component---src-pages-en-ca-checkout-js" */),
  "component---src-pages-en-ca-company-privacy-policy-js": () => import("./../../../src/pages/en-ca/company/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-ca-company-privacy-policy-js" */),
  "component---src-pages-en-ca-company-terms-conditions-js": () => import("./../../../src/pages/en-ca/company/terms-conditions.js" /* webpackChunkName: "component---src-pages-en-ca-company-terms-conditions-js" */),
  "component---src-pages-en-ca-faqs-js": () => import("./../../../src/pages/en-ca/faqs.js" /* webpackChunkName: "component---src-pages-en-ca-faqs-js" */),
  "component---src-pages-en-ca-get-in-touch-js": () => import("./../../../src/pages/en-ca/get-in-touch.js" /* webpackChunkName: "component---src-pages-en-ca-get-in-touch-js" */),
  "component---src-pages-en-ca-index-js": () => import("./../../../src/pages/en-ca/index.js" /* webpackChunkName: "component---src-pages-en-ca-index-js" */),
  "component---src-pages-en-ca-pricing-js": () => import("./../../../src/pages/en-ca/pricing.js" /* webpackChunkName: "component---src-pages-en-ca-pricing-js" */),
  "component---src-pages-en-ca-process-js": () => import("./../../../src/pages/en-ca/process.js" /* webpackChunkName: "component---src-pages-en-ca-process-js" */),
  "component---src-pages-en-ca-quote-email-js": () => import("./../../../src/pages/en-ca/quote-email.js" /* webpackChunkName: "component---src-pages-en-ca-quote-email-js" */),
  "component---src-pages-en-ca-trademark-search-js": () => import("./../../../src/pages/en-ca/trademark-search.js" /* webpackChunkName: "component---src-pages-en-ca-trademark-search-js" */),
  "component---src-pages-en-ca-why-us-js": () => import("./../../../src/pages/en-ca/why-us.js" /* webpackChunkName: "component---src-pages-en-ca-why-us-js" */),
  "component---src-pages-en-us-affiliate-program-js": () => import("./../../../src/pages/en-us/affiliate-program.js" /* webpackChunkName: "component---src-pages-en-us-affiliate-program-js" */),
  "component---src-pages-en-us-articles-js": () => import("./../../../src/pages/en-us/articles.js" /* webpackChunkName: "component---src-pages-en-us-articles-js" */),
  "component---src-pages-en-us-book-free-consult-js": () => import("./../../../src/pages/en-us/book-free-consult.js" /* webpackChunkName: "component---src-pages-en-us-book-free-consult-js" */),
  "component---src-pages-en-us-checkout-js": () => import("./../../../src/pages/en-us/checkout.js" /* webpackChunkName: "component---src-pages-en-us-checkout-js" */),
  "component---src-pages-en-us-company-privacy-policy-js": () => import("./../../../src/pages/en-us/company/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-us-company-privacy-policy-js" */),
  "component---src-pages-en-us-company-terms-conditions-js": () => import("./../../../src/pages/en-us/company/terms-conditions.js" /* webpackChunkName: "component---src-pages-en-us-company-terms-conditions-js" */),
  "component---src-pages-en-us-faqs-js": () => import("./../../../src/pages/en-us/faqs.js" /* webpackChunkName: "component---src-pages-en-us-faqs-js" */),
  "component---src-pages-en-us-get-in-touch-js": () => import("./../../../src/pages/en-us/get-in-touch.js" /* webpackChunkName: "component---src-pages-en-us-get-in-touch-js" */),
  "component---src-pages-en-us-index-js": () => import("./../../../src/pages/en-us/index.js" /* webpackChunkName: "component---src-pages-en-us-index-js" */),
  "component---src-pages-en-us-pricing-js": () => import("./../../../src/pages/en-us/pricing.js" /* webpackChunkName: "component---src-pages-en-us-pricing-js" */),
  "component---src-pages-en-us-process-js": () => import("./../../../src/pages/en-us/process.js" /* webpackChunkName: "component---src-pages-en-us-process-js" */),
  "component---src-pages-en-us-quote-email-js": () => import("./../../../src/pages/en-us/quote-email.js" /* webpackChunkName: "component---src-pages-en-us-quote-email-js" */),
  "component---src-pages-en-us-trademark-search-js": () => import("./../../../src/pages/en-us/trademark-search.js" /* webpackChunkName: "component---src-pages-en-us-trademark-search-js" */),
  "component---src-pages-en-us-why-us-js": () => import("./../../../src/pages/en-us/why-us.js" /* webpackChunkName: "component---src-pages-en-us-why-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-nlp-developer-js": () => import("./../../../src/pages/jobs/nlp-developer.js" /* webpackChunkName: "component---src-pages-jobs-nlp-developer-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-quote-email-js": () => import("./../../../src/pages/quote-email.js" /* webpackChunkName: "component---src-pages-quote-email-js" */),
  "component---src-pages-trademark-search-js": () => import("./../../../src/pages/trademark-search.js" /* webpackChunkName: "component---src-pages-trademark-search-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-templates-knowledge-article-knowledge-article-js": () => import("./../../../src/templates/knowledge-article/knowledge-article.js" /* webpackChunkName: "component---src-templates-knowledge-article-knowledge-article-js" */)
}

