import React from 'react'
import _ from 'lodash'

export const CheckoutStateContext = React.createContext()
export const CheckoutDispatchContext = React.createContext()

const intialState = {
  customer_info: {
    fullName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    cardName: '',
    applicantType: 'an individual'
  },
  customer_address: {
    country: '',
    address: '',
    city: '',
    state: '',
    zip: ''
  },
  company_info: {
    services: '',
    website: ''
  },
  trademarks: {
    names: [],
    logos: [],
    taglines: []
  },
  validSteps: {
    step0: {
      valid: false
    },
    step1: {
      valid: false
    },
    step2: {
      valid: false
    },
    step3: {
      valid: true
    },
    step4: {
      valid: true
    },
    step5: {
      valid: true
    }
  },
  current_step: 0,
  order_id: 0,
  client_secret: '',
  intentId: '',
  payment_type: '',
  source_website: 'heirlume'
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_TRADEMARK': {
      return {
        ...state,
        trademarks: {
          ...state.trademarks,
          [action.payload.type]: [
            ...state.trademarks[action.payload.type],
            { [action.payload.key]: action.payload.value }
          ]
        }
      }
    }
    case 'UPDATE_TRADEMARK': {
      var index = _.findIndex(
        state.trademarks[action.payload.type],
        function (o) {
          return Object.keys(o)[0] == action.payload.key
        }
      )
      let newArray = state.trademarks[action.payload.type].slice()
      newArray.splice(index, 1, { [action.payload.key]: action.payload.value })
      return {
        ...state,
        trademarks: {
          ...state.trademarks,
          [action.payload.type]: newArray
        }
      }
    }
    case 'REMOVE_TRADEMARK': {
      var ind = _.findIndex(
        state.trademarks[action.payload.type],
        function (o) {
          return Object.keys(o)[0] == action.payload.key
        }
      )
      let newArray = state.trademarks[action.payload.type].slice()
      newArray.splice(ind, 1)
      return {
        ...state,
        trademarks: {
          ...state.trademarks,
          [action.payload.type]: newArray
        }
      }
    }
    case 'SET_TRADEMARK_LOGOS': {
      return {
        ...state,
        trademarks: {
          ...state.trademarks,
          [action.payload.type]: action.payload.value
        }
      }
    }
    case 'SET_COMPANY_INFO': {
      return {
        ...state,
        company_info: {
          ...state.company_info,
          [action.payload.type]: action.payload.value
        }
      }
    }
    case 'SET_PAYMENT_TYPE': {
      return {
        ...state,
        payment_type: action.payload.value
      }
    }
    case 'SET_CLIENT_SECRET': {
      return {
        ...state,
        client_secret: action.payload.value
      }
    }
    case 'SET_INTENT_ID': {
      return {
        ...state,
        intentId: action.payload.value
      }
    }
    case 'SET_ORDER_ID': {
      return {
        ...state,
        order_id: action.payload.value
      }
    }
    case 'SET_STEP': {
      return {
        ...state,
        current_step: action.payload.value
      }
    }
    case 'UPDATE_STEP': {
      return {
        ...state,
        validSteps: {
          ...state.validSteps,
          [action.payload.type]: { valid: action.payload.value }
        }
      }
    }
    case 'SET_CUSTOMER_INFO': {
      return {
        ...state,
        customer_info: {
          ...state.customer_info,
          [action.payload.type]: action.payload.value
        }
      }
    }
    case 'SET_CUSTOMER_ADDRESS': {
      return {
        ...state,
        customer_address: {
          ...state.customer_address,
          [action.payload.type]: action.payload.value
        }
      }
    }
    case 'RESET_CHECKOUT': {
      return {
        ...state,
        customer_info: {
          fullName: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          cardName: '',
          applicantType: 'an individual'
        },
        customer_address: {
          country: '',
          address: '',
          city: '',
          state: '',
          zip: ''
        },
        company_info: {
          services: '',
          website: ''
        },
        trademarks: {
          names: [],
          logos: [],
          taglines: []
        },
        validSteps: {
          step0: {
            valid: false
          },
          step1: {
            valid: false
          },
          step2: {
            valid: false
          },
          step3: {
            valid: true
          },
          step4: {
            valid: true
          },
          step5: {
            valid: true
          }
        },
        client_secret: '',
        intentId: '',
        payment_type: ''
      }
    }
    case 'SET_SOURCE_WEBSITE': {
      return {
        ...state,
        source_website: action.payload.value
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

const CheckoutContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, intialState)

  //   useEffect(() => {
  //     console.warn(state)
  //   }, [state])

  return (
    <CheckoutStateContext.Provider value={state}>
      <CheckoutDispatchContext.Provider value={dispatch}>
        {children}
      </CheckoutDispatchContext.Provider>
    </CheckoutStateContext.Provider>
  )
}

export default CheckoutContextProvider
