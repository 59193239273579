import React, { useEffect } from 'react'
import { findIndex } from 'lodash'

export const StateContext = React.createContext()
export const DispatchContext = React.createContext()

const intialState = {
  cart: [],
  package_type: 'essentials',
  country_selection: '',
  province: '',
  discount_code: '',
  discount_total: 0,
  discount_percentage: 0,
  discount_type: '',
  total_price: 0,
  productsData: [],
  subtotal: 0,
  taxes: 0,
  prices: {
    name: 0,
    logo: 0,
    tagline: 0
  },
  essential_diff: 0,
  premium_diff: 0,
  billing_address: {
    address: '',
    city: '',
    country: '',
    state: '',
    zip: ''
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_DISCOUNT_PERCENTAGE': {
      return {
        ...state,
        discount_percentage: action.payload.value
      }
    }
    case 'SET_DISCOUNT_TOTAL': {
      return {
        ...state,
        discount_total: action.payload.value
      }
    }
    case 'SET_DISCOUNT': {
      return {
        ...state,
        discount_code: action.payload.value
      }
    }
    case 'SET_DISCOUNT_TYPE': {
      return {
        ...state,
        discount_type: action.payload.value
      }
    }
    case 'SET_SERVICE_LEVEL': {
      return {
        ...state,
        package_type: action.payload.value
      }
    }
    case 'SET_COUNTRY': {
      return {
        ...state,
        country_selection: action.payload.value
      }
    }
    case 'SET_PROVINCE': {
      return {
        ...state,
        province: action.payload.value
      }
    }
    case 'SET_PRODUCTS': {
      return {
        ...state,
        productsData: action.payload.value
      }
    }
    case 'CLEAN_CART': {
      return {
        ...state,
        cart: state.cart.filter((item) => {
          return item.qty
        })
      }
    }
    case 'SHUFFLE_INITIAL': {
      var newCart = state.cart.filter((item) => {
        return item.qty
      })
      try {
        if (findIndex(newCart, { selection: 'initial' }) == -1) {
          newCart = [
            ...newCart,
            {
              type: newCart[0].type,
              qty: 1,
              selection: 'initial'
            }
          ]
          newCart[0].qty -= 1
        }
      } catch (error) { }
      return {
        ...state,
        cart: newCart
      }
    }
    case 'SET_SUBTOTAL': {
      return {
        ...state,
        subtotal: action.payload.value,
        prices: action.payload.individual,
        taxes: action.payload.tax,
        total_price: action.payload.ttlPrice,
        essential_diff: action.payload.ess_diff,
        premium_diff: action.payload.prem_diff
      }
    }
    case 'UPDATE_CART': {
      return {
        ...state,
        cart: [...state.cart, action.payload.newCart]
      }
    }
    case 'UPDATE_CART_ITEM': {
      var newArray = [...state.cart]
      newArray[action.payload.itemIndex].qty = action.payload.newItem.qty
      return {
        ...state,
        cart: newArray
      }
    }
    case 'SET_BILLING_ADDRESS': {
      return {
        ...state,
        billing_address: {
          ...state.billing_address,
          [action.payload.type]: action.payload.value
        }
      }
    }
    case 'RESET_CONFIGURATOR': {
      return {
        cart: [],
        package_type: 'essentials',
        country_selection: '',
        province: '',
        discount_code: '',
        discount_total: 0,
        discount_percentage: 0,
        total_price: 0,
        productsData: [],
        subtotal: 0,
        taxes: 0,
        prices: {
          name: 0,
          logo: 0,
          tagline: 0
        },
        essential_diff: 0,
        premium_diff: 0,
        billing_address: {
          address: '',
          city: '',
          country: '',
          state: '',
          zip: ''
        }
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

const TrademarkConfiguratorContextProvider = ({ children, locale }) => {
  const [state, dispatch] = React.useReducer(reducer, intialState)

  useEffect(() => {
    document.sezzleConfig = {
      configGroups: [
        {
          targetXPath: '#ProductPrice/SPAN/P',
          renderToPath: '.sezzle__container',
          scaleFactor: '0.90',
          theme: 'dark'
        }
      ]
    }
    // workaround for dynamic total display as sezzle doesn't update the total
    try {
      document.querySelector('span.sezzle-payment-amount').innerHTML = `$${(
        state.total_price / 4
      ).toFixed(2)}`
    } catch (error) { }
  }, [state.total_price])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default TrademarkConfiguratorContextProvider
