import axios from 'axios'

export default axios.create({
  baseURL: `${process.env.GATSBY_LARAVEL_URL}/api`,
  headers: {
    'X-APPLICATION-ID': process.env.GATSBY_LARAVEL_CLIENT_ID,
    'X-APPLICATION-KEY': process.env.GATSBY_LARAVEL_CLIENT_KEY,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
