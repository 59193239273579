/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import GlobalContextProvider from './src/context/GlobalContextProvider'
import './src/styles/main.css'
import * as Sentry from '@sentry/react'

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  environment: process.env.GATSBY_APP_ENV,
  integrations: function (integrations) {
    // integrations will be all default integrations
    return integrations.filter(function (integration) {
      return (
        integration.name !== 'GlobalHandlers' &&
        integration.name !== 'TryCatch' &&
        integration.name !== 'Dedupe'
      )
    })
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
})

export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>
}
