import React, { useEffect } from 'react'
import TrademarkConfiguratorContextProvider from './TrademarkConfiguratorContextProvider'
import CheckoutContextProvider from './CheckoutContextProvider'
import { hotjar } from 'react-hotjar'
import { isMyScriptLoaded } from '../helpers'
import axios from '../axios'
import Cookies from 'js-cookie'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const intialState = {
  locale: 'en-CA',
  location: '/',
  loading: true
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOCALE': {
      return {
        ...state,
        locale: action.payload.locale
      }
    }
    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload.loading
      }
    }
    case 'SET_LOCATION': {
      return {
        ...state,
        location: action.payload.location,
        search: action.payload.search
      }
    }
    case 'SET_TRADEMARK': {
      return {
        ...state,
        trademark: action.payload.trademark
      }
    }
    default:
      throw new Error('invalid action type')
  }
}

// retrieves the gclid from _gac cookie
export const getGclid = () => {
  const cookiesObj = {}
  // Parse cookies and return gclid value

  const allCookies = document.cookie.split(';')

  allCookies.forEach((c) => {
    const cookie = c.trim().split('=')
    cookiesObj[cookie[0]] = cookie[1]
  })
  const gclidCookie =
    cookiesObj[`_gac_${process.env.GATSBY_GOOGLE_ANALYTICS_ID}`]
  if (gclidCookie) {
    return gclidCookie?.split('.')[2]
  }
  return ''
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, intialState)

  const initiateHotjar = () => {
    const hjid = 1880861
    const hjsv = 6
    hotjar.initialize(hjid, hjsv)
  }

  const getLocale = async () => {
    var locale = Cookies.get('hl-location')
    var isMobile = Cookies.get('hl-agent')

    if (
      !locale ||
      locale === undefined ||
      !isMobile ||
      isMobile === undefined
    ) {
      try {
        const { data } = await axios.get(`/my/location`)
        isMobile = data.isMobile
        locale = data?.country === 'CA' ? 'en-CA' : 'en-US'
      } catch (e) {
        console.log(e)
        locale = 'en-CA'
      }

      const urlLocale = window.location.pathname.split('/')

      if (urlLocale[1] === 'en-us') {
        locale = 'en-US'
      } else if (urlLocale[1] === 'en-ca') {
        locale = 'en-CA'
      }

      Cookies.set('hl-location', locale)
      Cookies.set('hl-agent', isMobile)
    }

    // initiate hotjar for all devices except mobile
    if (['false', false].includes(isMobile)) {
      initiateHotjar()
    }

    dispatch({ type: 'SET_LOCALE', payload: { locale: locale } })

    dispatch({ type: 'SET_LOADING', payload: { loading: false } })
  }

  useEffect(() => {
    getLocale()
    // initilizing google analytics
    window.dataLayer = window.dataLayer || []
  }, [])

  useEffect(() => {
    // paypal dynamic sdk based on currency
    var currency = state.locale == 'en-CA' ? 'CAD' : 'USD'
    const script = document.createElement('script')
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CLIENT_ID}&currency=${currency}`
    script.async = true
    script.defer = true
    if (
      isMyScriptLoaded(
        `https://www.paypal.com/sdk/js?client-id=${process.env.GATSBY_PAYPAL_CLIENT_ID}&currency=${currency}`
      )
    ) {
      try {
        document.head.removeChild(script)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    } else {
      document.head.appendChild(script)
    }
  }, [state.locale])

  return (
    // Main state
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {/* Configurator state */}
        <TrademarkConfiguratorContextProvider>
          {/* Checkout state */}
          <CheckoutContextProvider>{children}</CheckoutContextProvider>
        </TrademarkConfiguratorContextProvider>
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider
