export const theme = {
  colors: {
    white: '#fff',
    black: '#000',
    primary: '#be4816',
    beige: '#f4ede7',
    darkBeige: '#eadcd2',
    grey: '#222e35',
    secondary: '#222e35',
    lightGrey: '#222e35bf',
    dividerGrey: '#999da0',
    brown: '#cba88e',
    lightGreen: '#dae8ea',
    silver: '#e2e2e2',
    green: '#5FB246',
    orange: '#FF9213',
    red: '#EF3C24'
  },
  breakpoints: {
    xs: '479px',
    sm: '767px',
    md: '991px',
    lg: '1280px',
    xl: '1920px'
  }
}
